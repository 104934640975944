import { admin2, orgAdmin } from './instance'

export const GetBeautyAIAptSchedulePrediction = async ({
  shopId,
  startAt,
  endAt,
}) => {
  return await admin2({
    url: `/${shopId}/beautyAIAptSchedulePrediction/getPrediction`,
    method: 'GET',
    params: {
      startAt,
      endAt,
    },
  })
}

// => 人員績效
export const GetBeautyAIAptUnitAnalysis = async ({
  shopId,
  name,
  appointmentServiceId,
  start,
  limit,
}) => {
  return await admin2({
    url: `/${shopId}/beautyAIAptUnitAnalysis`,
    method: 'GET',
    params: {
      name,
      appointmentServiceId,
      start,
      limit,
    },
  })
}

export const GetBeautyAIAptUnitAnalysisCount = async ({
  shopId,
  name,
  appointmentServiceId,
}) => {
  return await admin2({
    url: `/${shopId}/beautyAIAptUnitAnalysis/count`,
    method: 'GET',
    params: {
      name,
      appointmentServiceId,
    },
  })
}

export const FindBeautyAIAptUnitAnalysis = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/beautyAIAptUnitAnalysis/${id}`,
    method: 'GET',
  })
}

// admin.beautyAIAptUnitAnalysis.getYearlyChart
export const GetBeautyAIAptUnitAnalysisYearlyChart = async ({
  shopId,
  id,
}) => {
  return await admin2({
    url: `/${shopId}/beautyAIAptUnitAnalysis/${id}/yearlyChart`,
    method: 'GET',
  })
}

// => 會員分析
// GET admin.beautyAIMemberAnalysis.get360View
export const GetBeautyAIMemberAnalysis360View = async ({
  shopId,
  memberId,
}) => {
  return await admin2({
    url: `/${shopId}/beautyAIMemberAnalysis/get360View`,
    method: 'GET',
    params: {
      memberId,
    },
  })
}

// => 銷售產品
// GET admin.beautyAISalesProductAnalysis.getChart
export const GetBeautyAISalesProductAnalysisChart = async ({
  shopId,
  dateTrunc,
  year,
}) => {
  return await admin2({
    url: `/${shopId}/beautyAISalesProductAnalysis/getChart`,
    method: 'GET',
    params: {
      dateTrunc,
      year,
    },
  })
}

// GET admin.beautyAISalesProductAnalysis.getYearOptions
export const GetBeautyAISalesProductAnalysisYearOptions = async ({
  shopId,
}) => {
  return await admin2({
    url: `/${shopId}/beautyAISalesProductAnalysis/yearOptions`,
    method: 'GET',
  })
}

// --- ORG Scope ---

// GET orgAdmin.beautyAIAptUnitAnalysis.find
export const GetOrgBeautyAIAptUnitAnalysis = async ({
  orgId,
  shopIds,
  name,
  start,
  limit,
}) => {
  return await orgAdmin({
    url: `/${orgId}/beautyAIAptUnitAnalysis`,
    method: 'GET',
    params: {
      shopIds,
      name,
      start,
      limit,
    },
  })
}

// GET orgAdmin.beautyAIAptUnitAnalysis.count
export const GetOrgBeautyAIAptUnitAnalysisCount = async ({
  orgId,
  shopIds,
  name,
}) => {
  return await orgAdmin({
    url: `/${orgId}/beautyAIAptUnitAnalysis/count`,
    method: 'GET',
    params: {
      shopIds,
      name,
    },
  })
}

// GET orgAdmin.beautyAIAptUnitAnalysis.findOne
export const FindOrgBeautyAIAptUnitAnalysis = async ({
  orgId,
  shopId,
  id,
}) => {
  return await orgAdmin({
    url: `/${orgId}/beautyAIAptUnitAnalysis/${id}`,
    method: 'GET',
    params: {
      shopId,
    },
  })
}

// GET orgAdmin.beautyAIAptUnitAnalysis.getYearlyChart
export const GetOrgBeautyAIAptUnitAnalysisYearlyChart = async ({
  orgId,
  shopId,
  id,
}) => {
  return await orgAdmin({
    url: `/${orgId}/beautyAIAptUnitAnalysis/${id}/yearlyChart`,
    method: 'GET',
    params: {
      shopId,
    },
  })
}

// GET orgAdmin.beautyAISalesProductAnalysis.getYearOptions
export const GetOrgBeautyAISalesProductAnalysisYearOptions = async ({
  orgId,
  shopId,
}) => {
  return await orgAdmin({
    url: `/${orgId}/beautyAISalesProductAnalysis/yearOptions`,
    method: 'GET',
    params: {
      shopId,
    },
  })
}

// GET orgAdmin.beautyAISalesProductAnalysis.getChart
export const GetOrgBeautyAISalesProductAnalysisChart = async ({
  orgId,
  shopId,
  dateTrunc,
  year,
}) => {
  return await orgAdmin({
    url: `/${orgId}/beautyAISalesProductAnalysis/getChart`,
    method: 'GET',
    params: {
      shopId,
      dateTrunc,
      year,
    },
  })
}
